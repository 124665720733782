import { addAutocomplete } from '../autocomplete'
import { DOMReady } from '../lib/domready'

DOMReady(main)

function handleSearch() {
  const input = document.querySelector('#search_cta_text')
  if (!input) {
    return
  }

  const form = input.closest('form')
  const refInput = document.querySelector(input.dataset.selectorRef)
  const textInput = document.querySelector(input.dataset.selectorText)
  const searchInput = document.querySelector(input.dataset.selectorSearch)
  const typeInput = document.querySelector(input.dataset.selectorType)
  const priceInput = document.querySelector(input.dataset.selectorPrice)
  const searchTextInput = document.querySelector(
    input.dataset.selectorSearchText
  )
  const searchTypeInput = document.querySelector(
    input.dataset.selectorSearchType
  )

  if (
    !form ||
    !refInput ||
    !textInput ||
    !searchInput ||
    !typeInput ||
    !priceInput ||
    !searchTextInput ||
    !searchTypeInput
  ) {
    return
  }

  const dataSource = (term) => {
    const url = `${input.dataset.route}?term=${term}&mode=cta`
    return fetch(url)
      .then((res) => res.json())
      .catch((err) => console.error('Error while fetching autocomplete', err))
  }

  const onSelect = (el) => {
    if (el.extra) {
      refInput.value = null
      textInput.value = null
      searchInput.value = el.extra.queryString
      searchTextInput.value = el.text
      searchTypeInput.value = 'top-search'
      if (el.extra.type) {
        typeInput.value = el.extra.type
      }
      if (el.extra.priceMax) {
        priceInput.value = el.extra.priceMax
      }
    } else if (el.id.startsWith('p:')) {
      const sliced = el.id.slice('p:'.length)
      input.value = sliced
      refInput.value = null
      textInput.value = sliced
      searchInput.value = null
      searchTextInput.value = sliced
      searchTypeInput.value = 'user-entry'
    } else {
      refInput.value = el.id
      textInput.value = null
      searchInput.value = null
      searchTextInput.value = el.text
      searchTypeInput.value = 'category'
    }
  }

  const options = {
    delay: 300,
    sort: false,
  }

  form.addEventListener('submit', (evt) => {
    if (searchInput.value) {
      evt.preventDefault()
      const qs = new URLSearchParams(searchInput.value)
      if (
        typeInput.value &&
        !searchInput.value.includes(encodeURIComponent(typeInput.value))
      ) {
        qs.append(typeInput.name, typeInput.value)
      }
      if (priceInput.value && !qs.has(priceInput.name)) {
        qs.append(priceInput.name, priceInput.value)
      }
      qs.append(searchTextInput.name, searchTextInput.value)
      qs.append(searchTypeInput.name, searchTypeInput.value)

      window.location = `${form.action}?${qs}`
    }
  })

  addAutocomplete(input, dataSource, onSelect, options)
}

function main() {
  handleSearch()
}
